import whenDomReady from 'when-dom-ready';

const DEFAULT_FORM_TARGET_URL = 'https://mailform.haufe.com/form/fc';
const ERROR_MSG = 'Captcha-Validierung fehlgeschlagen. Bitte laden Sie die Seite neu.';

const DEFAULT_FC_VERSION = 1;

whenDomReady().then(async () => {
   const thisScriptElement = document.querySelector('script[src$="mailform-webflow.js"]');
   const targetUrl = thisScriptElement?.dataset.formTarget || DEFAULT_FORM_TARGET_URL;
   const rawFcVersion = thisScriptElement?.dataset.fcVersion || DEFAULT_FC_VERSION;
   const fcVersion = parseInt(rawFcVersion, 10);

   const Webflow = window.Webflow || [];

   let sitekey = null;
   let isForwarding = false;
   let _fc = null;

   const formElements = document.querySelectorAll('form.mailform');
   const handlerMap = new Map();
   const honeypotsMap = new Map();
   const fcWidgetsMap = new Map();

   async function getFC() {
      if (!_fc) {
         if (fcVersion === 2) {
            const { FriendlyCaptchaSDK } = await import('@friendlycaptcha/sdk');
            _fc = new FriendlyCaptchaSDK();
         } else {
            const fc = await import('friendly-challenge');
            console.log(fc);
            _fc = fc;
         }
      }

      return _fc;
   }

   async function processForms (executingResolver = () => null) {
      executingResolver();

      for (const formElement of formElements) {
         const needsCaptcha = formElement.matches('.friendlycaptcha');
         if (needsCaptcha) {
            const submitButton = formElement.querySelector('[type="submit"]')
            submitButton.disabled = true;

            if (!sitekey && formElement.dataset.sitekey) {
               sitekey = formElement.dataset.sitekey;
            }

            const widgetElement = document.createElement('DIV');
            // The widget goes to the top-level of the form, before the submit
            // button (and all its wrappers / containers)
            const explicitInsertionPoint = formElement.querySelector('[data-fc-widget]');
            if (explicitInsertionPoint) {
               explicitInsertionPoint.appendChild(widgetElement);
            } else {
               const insertionPoint = submitButton.closest('form > *');
               formElement.insertBefore(widgetElement, insertionPoint);
            }

            const fc = await getFC();

            let widget;
            if (fcVersion === 1) {
               const htmlLang = document
                   .querySelector('html')
                   ?.getAttribute('lang') || 'de';
               widget = new fc.WidgetInstance(widgetElement, {
                  sitekey,
                  language: htmlLang,
                  doneCallback: () => {
                     submitButton.disabled = false;
                  }
               });
            } else {
               widget = fc.createWidget({
                  element: widgetElement,
                  sitekey,
               });
               widget.addEventListener('frc:widget.complete', () => {
                  submitButton.disabled = false;
               })
            }

            fcWidgetsMap.set(formElement, widget);
         }

         // Add honeypot field required by MailformApp for spam protection
         if (!formElement.querySelector('input[name="web"]')) {
            const honeypot = document.createElement('INPUT');
            honeypot.type = 'hidden';
            honeypot.name = 'web';
            formElement.appendChild(honeypot);
            honeypotsMap.set(formElement, honeypot);
         }

         // Add our custom submit event listener
         const jQuery = window.$ || window.jQuery;
         if (jQuery?.data(formElement, '.wForm').handler) {
            handlerMap.set(formElement, jQuery.data(formElement, '.wForm').handler);
            jQuery.data(formElement, '.wForm').handler = onSubmit;
         } else {
            formElement.addEventListener('submit', onSubmit, {capture: true});
         }
      }
   }

   function handleFailureWebflow(wfFormData, submitButton) {
      submitButton?.remove();
      wfFormData.fail.append(ERROR_MSG)
      wfFormData.fail.show();
   }

   function handleFailure(formElement, submitButton) {
      submitButton?.remove();
      const error = document.createElement('DIV');
      error.textContent = ERROR_MSG;
      error.style = 'padding: 1em; background-color: darksalmon; color: #000; margin: 1em 0; border: 1px solid #b00;';

      if (submitButton) {
         const insertionPoint = submitButton.closest('form > *');
         formElement.insertBefore(error, insertionPoint);
      } else {
         formElement.appendChild(error);
      }
   }

   async function onSubmit(e) {
      if (isForwarding) {
         isForwarding = false;
         return;
      }

      let formElement;
      /** @var {HTMLButtonElement | HTMLInputElement | null} submitButton */
      let submitButton = null;
      let wfFormData = null;
      let event;

      if (e.evt) {
         // In this case we're being called by Webflow's jQuery-based  mechanism
         // with synthetic event data (see handler replacement above).
         // e is formElementData
         event = e.evt || null;
         formElement = e.form.get(0) || null;
         wfFormData = e;
         submitButton = e.btn.get(0) || null;
      } else {
         // This is a regular submit event we're catching, with no Webflow
         // involved.
         event = e;
         formElement = e.target;
         submitButton = formElement.querySelector('[type="submit"]')
      }

      event?.preventDefault();
      event?.stopImmediatePropagation();

      if (submitButton) {
         submitButton.disabled = true;
      }

      // Add Webflow form data items.
      const rawFormData = new FormData(formElement);
      const submittedFormData = new FormData;
      // Only keep fields that are required for Captcha validation and spam
      // check:
      const transmittedFields = [
         'web',
         'frc-captcha-solution',
         'frc-captcha-response',
      ];

      transmittedFields.forEach(key => {
         if (rawFormData.has(key)) {
            submittedFormData.set(key, rawFormData.get(key));
         }
      });

      let success = false;
      try {
         const response = await fetch(targetUrl, {
            // Results in automatic content-type of "multipart/form-data" with the
            // correct boundaries set, as opposed to what happens when manually
            // setting the content-type header.
            body: submittedFormData,
            method: 'POST',
         });
         success = response.ok;
      } catch {
         success = false;
      }

      if (submitButton) {
         submitButton.disabled = !success;
      }

      if (!success) {
         if (wfFormData) {
            handleFailureWebflow(wfFormData, submitButton);
         } else {
            handleFailure(formElement, submitButton)
         }
      } else if (handlerMap.has(formElement)) {
         honeypotsMap.has(formElement) && honeypotsMap.get(formElement).remove();
        if (fcWidgetsMap.has(formElement)) {
           const widget = fcWidgetsMap.get(formElement);
           console.log({ widget });
           if (typeof widget.getElement === 'function') {
              widget.getElement().remove();
           } else if (typeof widget.e?.remove === 'function') {
              widget.e.remove();
           }
        }
         handlerMap.get(formElement)(e);
      } else {
         isForwarding = true;
         formElement.requestSubmit();
      }
   }

   // ensure we're running after webflow has attached their event handling etc.
   if (formElements.length) {
      const {promise, resolve} = Promise.withResolvers();
      let aborted = false;

      Webflow.push(() => {
         processForms(resolve).then();
      });

      await Promise.race([
          promise,
          new Promise(res => {setTimeout(() => {aborted = true; res()}, 2500)})
      ]);

      if (aborted) {
         processForms().then();
      }
   }
});


